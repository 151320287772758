function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/user/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const userRoutes = [
  {
    path: '/user/dashboard',
    component: view('Dashboard'),
    name: 'user.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/reservations/pending',
    component: view('Pending'),
    name: 'user.reservations.pending',
    meta: {
      breadcrumb: [
        {
          text: 'Reservations'
        },
        {
          text: 'Pending',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/reservations/histories',
    component: view('Histories'),
    name: 'user.reservations.histories',
    meta: {
      breadcrumb: [
        {
          text: 'Reservations'
        },
        {
          text: 'Histories',
          active: true
        }
      ]
    }
  }
]

export default userRoutes
