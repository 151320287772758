function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/logistics-officer/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const logisticsOfficerRoutes = [
  {
    path: '/logistics-officer/dashboard',
    component: view('Dashboard'),
    name: 'logistics-officer.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/reservations/pending',
    component: view('reservations/Pending'),
    name: 'logistics-officer.reservations.pending',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Pending Reservations',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/reservations/histories/:status?',
    component: view('reservations/Histories'),
    name: 'logistics-officer.reservations.histories',
    meta: {
      status: 'All',
      breadcrumb: [
        {
          text: 'Reports'
        },
        {
          text: 'Transaction Histories',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/schedules/vehicles/pending',
    component: view('schedules/vehicles/Pending'),
    name: 'logistics-officer.vehicle-schedules.pending',
    meta: {
      breadcrumb: [
        {
          text: 'Vehicle Schedules'
        },
        {
          text: 'Pending',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/schedules/vehicles/histories',
    component: view('schedules/vehicles/Histories'),
    name: 'logistics-officer.vehicle-schedules.histories',
    meta: {
      breadcrumb: [
        {
          text: 'Vehicle Schedules'
        },
        {
          text: 'Histories',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/schedules/drivers/pending',
    component: view('schedules/drivers/Pending'),
    name: 'logistics-officer.driver-schedules.pending',
    meta: {
      breadcrumb: [
        {
          text: 'Driver Schedules'
        },
        {
          text: 'Pending',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/schedules/drivers/histories',
    component: view('schedules/drivers/Histories'),
    name: 'logistics-officer.driver-schedules.histories',
    meta: {
      breadcrumb: [
        {
          text: 'Driver Schedules'
        },
        {
          text: 'Histories',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenances/companies',
    component: view('Companies'),
    name: 'logistics-officer.maintenances.companies',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Companies',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenances/organizations',
    component: view('Organizations'),
    name: 'logistics-officer.maintenances.organizations',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Organizations',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenances/vehicle-types',
    component: view('VehicleTypes'),
    name: 'logistics-officer.maintenances.vehicle-types',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Vehicle Types',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenances/vehicles',
    component: view('Vehicles'),
    name: 'logistics-officer.maintenances.vehicles',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Vehicles',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenances/drivers',
    component: view('Drivers'),
    name: 'logistics-officer.maintenances.drivers',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenance'
        },
        {
          text: 'Drivers',
          active: true
        }
      ]
    }
  }
]

export default logisticsOfficerRoutes
