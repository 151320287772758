function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/admin/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const AdminRoutes = [
  {
    path: '/admin/dashboard',
    component: view('Dashboard'),
    name: 'admin.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/accounts/users',
    component: view('UserAccounts'),
    name: 'admin.accounts.users',
    meta: {
      breadcrumb: [
        {
          text: 'Accounts'
        },
        {
          text: 'Users',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/accounts/employees',
    component: view('EmployeeAccounts'),
    name: 'admin.accounts.employees',
    meta: {
      breadcrumb: [
        {
          text: 'Accounts'
        },
        {
          text: 'Employees',
          active: true
        }
      ]
    }
  }
]

export default AdminRoutes
