import ApiService from '@/services/core/api.service'

const ReservationService = {
  async get (query) {
    return ApiService.get(`logistics-officer/reservations?${query}`)
  },

  async histories (query) {
    return ApiService.get(`logistics-officer/reports/transaction-histories?${query}`)
  },

  async exports (query) {
    return ApiService.get(`logistics-officer/export/transaction-histories?${query}`)
  },

  async post (payload) {
    return ApiService.post('logistics-officer/reservations', payload)
  },

  async put (payload) {
    return ApiService.put(`logistics-officer/reservations/${payload.id}`, payload)
  },

  async delete (payload) {
    return ApiService.delete(`logistics-officer/reservations/${payload.id}`)
  }
}

export default ReservationService
