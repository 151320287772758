import RShared from './shared.routes'
import RAdmin from './admin.routes'
import RLogisticsOfficer from './logistics-officer.routes'
import RUser from './user.routes'

const allRoutes = []

export default allRoutes.concat(
  RShared,
  RAdmin,
  RLogisticsOfficer,
  RUser
)
